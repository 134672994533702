import React from "react";
import { Container } from "reactstrap";
import { Link } from "gatsby";
import MainNavigation from "./MainNavigation";

const Header = () => {
  return (
    <div className="header__wrapper">
      <Container>
        <header>
          <MainNavigation />
          <Link to="/">
            <h1 id="logo">
              <span>Chance Brilz</span>
            </h1>
          </Link>
        </header>
      </Container>
    </div>
  );
};

export default Header;
