import React, { useState } from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "gatsby";

const MainNavigation = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const navClass = showMobileNav ? "show-mobile-nav" : undefined;
  const handleClass = showMobileNav ? "collapse" : undefined;

  const toggleMobileNav = (show?: boolean) =>
    setShowMobileNav((current) => show ?? !current);

  return (
    <>
      <div
        id="mobile-nav-handle"
        onClick={toggleMobileNav.bind(null, undefined)}
        className={handleClass}
      >
        <span />
        <span />
        <span />
      </div>
      <Nav id="main-nav" className={navClass}>
        <div className="main-nav--wrapper">
          <NavItem>
            <Link
              to="/"
              className="nav-link"
              onClick={toggleMobileNav.bind(null, false)}
            >
              <span>Portfolio</span>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/blog"
              className="nav-link"
              onClick={toggleMobileNav.bind(null, false)}
            >
              <span>Blog</span>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/contact"
              className="nav-link"
              onClick={toggleMobileNav.bind(null, false)}
            >
              <span>Contact</span>
            </Link>
          </NavItem>
          <div className="social-links-container">
            <NavItem>
              <a
                className="icon icon-github nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href="//github.com/chancebrilz"
              >
                <span className="hidden">Github Profile (opens in a new window)</span>
              </a>
            </NavItem>
            <NavItem>
              <a
                className="icon icon-linkedin nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href="//linkedin.com/in/chancebrilz/"
              >
                <span className="hidden">LinkedIn Profile (opens in a new window)</span>
              </a>
            </NavItem>
          </div>
        </div>
      </Nav>
    </>
  );
};

export default MainNavigation;
