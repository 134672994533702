import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Header from "./Header";

// global styles
import "../../styles/styles.scss";

export const PageWrapper = ({ children }) => (
  <StaticQuery
    query={SiteTitleQuery}
    render={(data) => (
      <>
        <Header />
        <main id="container" className="container">
          {children}
        </main>
      </>
    )}
  />
);

const SiteTitleQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
